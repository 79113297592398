/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

import './bootstrap';
import React from 'react';
import ReactDOM from 'react-dom/client';

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// import './components/Example';

// Function to dynamically import a component based on a condition
import IndexHeader from './components/Home/Header';
import IndexClients from './components/Home/Clients';
import IndexFeatures from './components/Home/Features';
import IndexTestimonials from './components/Home/Testimonials';

const rootsCache = {};

// Array of components with their associated IDs
const componentsToRender = [
    { id: 'homepage-header', component: IndexHeader },
    { id: 'homepage-client', component: IndexClients },
    { id: 'homepage-features', component: IndexFeatures },
    { id: 'homepage-testimonials', component: IndexTestimonials },
];

// Function to render the React component if the element exists
function renderComponentIfElementExists(elementId, Component) {
    const element = document.getElementById(elementId);

    if (element) {
        // Check if the root already exists in the cache
        if (!rootsCache[elementId]) {
            // Create a new root and store it in the cache
            const root = ReactDOM.createRoot(element);
            rootsCache[elementId] = root;
            root.render(
                <Component />
            );
            // console.log(`Rendered ${Component.name} in element with ID: ${elementId}`);
        } else {
            // If the root exists, just render the component again
            rootsCache[elementId].render(
                <Component />
            );
            console.log(`Updated ${Component.name} in element with ID: ${elementId}`);
        }
    } else {
        console.warn(`No element found with ID: ${elementId}, skipping component load.`);
    }
}

// Iterate over the components array and render them conditionally
componentsToRender.forEach(({ id, component }) => {
    renderComponentIfElementExists(id, component);
});

export default { IndexHeader };
