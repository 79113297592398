import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomepageClient = () => {
    const [clientData, setClientData] = useState(null);
    const [clients, setClients] = useState([]);
    const fetchedDataRef = useRef(false);

    useEffect(() => {
        if (fetchedDataRef.current) return; // Skip if already fetched
        fetchedDataRef.current = true;

        axios.get('/front/clients')
            .then(response => {
                setClientData(response.data.trFrontDetail);
                setClients(response.data.frontClients);
            })
            .catch(error => {
                console.error('Error fetching client data:', error);
            });
    }, []);

    // Updated slider settings
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <style>
                {`
                    .slick-slide {
                    width: 250px !important;
                    }
                `}
            </style>
            {clientData && (clientData.client_title || clientData.client_detail || clients.length > 0) && (
                <div className="clients bg-light">
                    <div className="container">
                        <div className="row align-items-center">
                            {(clientData.client_title || clientData.client_detail) && (
                                <div className="col-12 mb-30 text-center">
                                    <p className="c-blue mb-2">{clientData.client_title}</p>
                                    <h4>{clientData.client_detail}</h4>
                                </div>
                            )}
                            {clients.length > 0 && (
                                <div className="col-12">
                                    <Slider {...sliderSettings} className="client-slider" id="client-slider">
                                        {clients.map((client, index) => (
                                            <div className="client-img" key={client.id}>
                                                <div className="img-holder">
                                                    <img src={client.image_url} alt={client.name || "Client"} />
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default HomepageClient;
