import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';

// Usage in your arrow components
const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="slick-arrow slick-prev"
            onClick={onClick}
            style={styles.arrow}
            onMouseOver={(e) => e.currentTarget.style.color = styles.arrowHover.color}
            onMouseOut={(e) => e.currentTarget.style.color = styles.arrow.color}
            onMouseDown={(e) => e.currentTarget.style.transform = styles.arrowActive.transform}
            onMouseUp={(e) => e.currentTarget.style.transform = 'translateY(-50%)'}
        >
            &#10094; {/* Left Arrow Character */}
        </button>
    );
};

const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="slick-arrow slick-next"
            onClick={onClick}
            style={styles.arrow}
            onMouseOver={(e) => e.currentTarget.style.color = styles.arrowHover.color}
            onMouseOut={(e) => e.currentTarget.style.color = styles.arrow.color}
            onMouseDown={(e) => e.currentTarget.style.transform = styles.arrowActive.transform}
            onMouseUp={(e) => e.currentTarget.style.transform = 'translateY(-50%)'}
        >
            &#10095; {/* Right Arrow Character */}
        </button>
    );
};

// Testimonial Section Component
const TestimonialSection = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [trFrontDetail, setTrFrontDetail] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response = await axios.get('/front/testimonials');
                setTrFrontDetail(response.data.trFrontDetail);
                setTestimonials(response.data.testimonials);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchTestimonials();
    }, []);

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024, // For medium screens
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768, // For tablets
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480, // For mobile devices
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    if (loading) return <p>Loading testimonials...</p>;
    if (error) return <p>Error loading testimonials: {error.message}</p>;
    if (testimonials.length === 0) return <p>No testimonials available.</p>;

    return (
        <section className="section-testimonial bg-white sp-100">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="sec-title mb-5">
                            <h3>{trFrontDetail.testimonial_title}</h3> {/* Displaying the title */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <Slider {...settings} className="testimonial-slider mb-0 text-center">
                            {testimonials.map((testimonial, index) => (
                                <div className="testimonial-item" key={index}>
                                    <div className="client-info">
                                        <p className="mb-4">{testimonial.comment}</p> {/* Displaying the comment */}
                                        <h5 className="mb-1">{testimonial.name}</h5> {/* Displaying the name */}
                                    </div>
                                    <div className="rating text-warning">
                                        {[...Array(5)].map((_, i) => (
                                            <i
                                                key={i}
                                                className={`zmdi ${testimonial.rating > i ? 'zmdi-star' : 'zmdi-star-border'}`}
                                            ></i> // Displaying the rating stars
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

const styles = {
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        textAlign: 'center',
    },
    loadingText: {
        fontSize: '1.5rem', // Adjust as needed
        color: '#000', // Change to your desired color
    },
    section: {
        padding: '50px 0',
        backgroundColor: '#f9f9f9',
    },
    title: {
        fontSize: '2rem',
        marginBottom: '30px',
        textAlign: 'center',
    },
    testimonialItem: {
        padding: '20px',
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
    },
    clientInfo: {
        marginBottom: '10px',
    },
    comment: {
        marginBottom: '4px',
    },
    name: {
        marginBottom: '1px',
    },
    rating: {
        color: 'orange', // Adjust color as needed
    },
    arrow: {
        position: 'absolute',
        top: '50%',
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '2rem',
        color: '#333', // Change to your desired color
        transform: 'translateY(-50%)', // Center the arrow vertically
        zIndex: 1, // Ensure arrows are above other elements
        transition: 'color 0.3s', // Smooth color transition
    },
    arrowHover: {
        color: '#B66DFF', // Change color on hover
    },
    arrowActive: {
        transform: 'scale(1.1)', // Scale up on click for feedback
    },
};

export default TestimonialSection;
