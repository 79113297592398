import React from 'react';

function IndexHeader() {
    return (
        <div className="">
            Header
        </div>
    );
}

export default IndexHeader;
