import React, { useEffect, useState } from 'react';
import axios from 'axios';

const HomepageFeatures = () => {
    const [featuresData, setFeaturesData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFeaturesData = async () => {
            try {
                const response = await axios.get('/front/features');
                setFeaturesData(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchFeaturesData();
    }, []);

    if (loading) return (
        <div style={styles.loadingContainer}>
            <p style={styles.loadingText}>Loading...</p>
        </div>
    );
    if (error) return <p>Error loading features: {error.message}</p>;

    const { featureWithImages, featureWithIcons, trFrontDetail } = featuresData;

    return (
        <>
            {featureWithImages && featureWithImages.length > 0 && (
                <>
                    {/* START Saas Features */}
                    {featureWithImages.map((value, index) => (
                        <section className="saas-features overflow-hidden" key={index}>
                            <div className="container">
                                <div className="sp-100">
                                    <div className="row align-items-center">
                                        {index % 2 === 0 ? (
                                            <>
                                                <div className="col-lg-6 order-lg-1 wow fadeIn d-lg-block" data-wow-delay="0.2s">
                                                    <div className="mock-img">
                                                        <img src={value.image_url} alt="mockup" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 pl-lg-5 order-lg-2">
                                                    <h3>{value.title}</h3>
                                                    <p dangerouslySetInnerHTML={{ __html: value.description }} />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-lg-6 pr-lg-5">
                                                    <h3>{value.title}</h3>
                                                    <p dangerouslySetInnerHTML={{ __html: value.description }} />
                                                </div>
                                                <div className="col-lg-6 wow fadeIn d-lg-block" data-wow-delay="0.2s">
                                                    <div className="mock-img">
                                                        <img src={value.image_url} alt="mockup" />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                    ))}
                    {/* END Saas Features */}
                </>
            )}

            {featureWithIcons && featureWithIcons.length > 0 && (
                <>
                    {/* START Features */}
                    <section className="features bg-light sp-100-70">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sec-title mb-60">
                                        <h3>{trFrontDetail.feature_title}</h3>
                                        <p>{trFrontDetail.feature_description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {featureWithIcons.map((featureWithIcon, index) => (
                                    <div className="col-lg-4 col-md-6 col-12 mb-30 wow fadeIn" data-wow-delay="0.2s" key={index}>
                                        <div className="feature-box bg-white br-5 text-center">
                                            <span className="align-items-center d-inline-flex icon justify-content-center mx-auto">
                                                <i className={featureWithIcon.icon}></i>
                                            </span>
                                            <h5>{featureWithIcon.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: featureWithIcon.description }} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    {/* END Features */}
                </>
            )}
        </>
    );
};

// Styles for loading message
const styles = {
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        textAlign: 'center',
    },
    loadingText: {
        fontSize: '1.5rem', // Adjust as needed
        color: '#000', // Change to your desired color
    },
};

export default HomepageFeatures;
